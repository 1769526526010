import { FC, ReactNode } from "react";
import { ArrowRightCircleIcon, ClockIcon, LockClosedIcon } from "@heroicons/react/24/outline";
import {  CheckCircleIcon } from "@heroicons/react/24/solid";

export interface TaskCardProps {
    title: string;
    description: string;
    icon: ReactNode;
    status: "todo" | "inprogress" | "done";
    onClick: () => void;
    disabled?: boolean;
    disabledOnDone?: boolean;
}

const TaskCard: FC<TaskCardProps> = ({ title, description, icon, status, onClick, disabled, disabledOnDone }) => {
  return (
    <button
      onClick={disabledOnDone ? undefined : onClick}
      disabled={disabled}
      className={`${disabled ? "opacity-60 cursor-not-allowed" : "opacity-100 hover:shadow-md hover:border hover:border-brand-dark cursor-pointer"}
       bg-white bg-opacity-80 text-left hover:border-opacity-100 shadow border border-brand-dark border-opacity-40  rounded-md p-4 relative focus:ring-2 focus:ring-offset-2
        focus:ring-brand-dark focus:ring-opacity-70 focus:outline-none`}
    >
      <div className="flex items-center gap-x-4 w-11/12">
        <div className="text-xl sm:text-3xl">
          {icon}
        </div>

        <div className="flex-1 flex flex-col justify-between">
          <div className="flex-1 sm:tracking-wide">
            <h3 className="text-base leading-tight sm:text-lg font-bold">{title}</h3>
            <p className="text-xs sm:text-sm opacity-80">{description}</p>
          </div>
        </div>

        <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
          {status === "todo" && (
            <>
              {disabled ? (
                <LockClosedIcon className="h-6 w-6 text-brand-dark" />
              ) : (
                <ArrowRightCircleIcon className="h-6 w-6 sm:h-7 sm:w-7 text-brand-dark" />
              )}
            </>
          )}
          {status === "inprogress" && (
            <ClockIcon className="h-6 w-6 sm:h-7 sm:w-7 text-brand-dark" aria-hidden="true" />
          )}
          {status === "done" && (
            <CheckCircleIcon className="h-6 w-6 sm:h-7 sm:w-7 text-brand-dark" aria-hidden="true" />
          )}
        </div>

      </div>
    </button>
  );
};

export default TaskCard;