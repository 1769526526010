import { useTranslation } from "next-i18next"
import { useEffect, useState } from "react"
import useStore from "store"

import Button from "components/ui/Button"
import { FullScreenModal } from "components/ui/Modal"

import MobileSteps from "../MobileSteps"
import Steps from "../Steps"
import TaskTitle from "../TaskTitle"
import WhyTaxModal from "../WhyTaxIdModal"
import UserAMLForm from "./UserAMLForm"
import UserInvestorExperience from "./UserInvestorExperience"
import UserTaxForm from "./UserTaxForm"

interface Props {
  isOpen: boolean
  onClose: () => void
}

const UserRegulatoryModal = ({ isOpen, onClose }: Props) => {
  const [step, setStep] = useState(0)
  const { t } = useTranslation("common")
  const store = useStore()

  useEffect(() => {
    if (!isOpen) {
      setStep(0)
    }
  }, [isOpen, onClose])

  return (
    <FullScreenModal isOpen={isOpen} onClose={onClose}>
      <div className="grid grid-cols-12 min-h-screen">
        <div className="col-span-3 hidden bg-brand-dark text-white min-h-screen sm:flex justify-center items-center">
          <Steps
            steps={[
              { name: t("task.regulatoryDetails.step1"), step: 0 },
              { name: t("task.regulatoryDetails.step2"), step: 1 },
              { name: t("task.regulatoryDetails.step3"), step: 3 },
            ]}
            currentStep={step}
            setStep={setStep}
          />
        </div>

        <div className="col-span-12 sm:col-span-9 flex items-center justify-center pt-16 sm:pt-0">
          <div className="flex flex-col p-4 max-w-lg w-full">
            {step === 0 && (
              <>
                <TaskTitle title={t("task.regulatoryDetails.titleTax")} text={t("task.regulatoryDetails.textTax")} />

                <UserTaxForm setStep={setStep} currentStep={step} />
              </>
            )}

            {step === 1 && (
              <>
                <TaskTitle title={t("task.regulatoryDetails.titleAML")} text={t("task.regulatoryDetails.textAML")} />

                <UserAMLForm setStep={setStep} currentStep={step} />
              </>
            )}

            {step === 2 && (
              <>
                <div className="col-span-12">
                  <TaskTitle title={t("form.investorExperience.title")} text={t("form.investorExperience.text")} />

                  <Button variant="primary" type="button" onClick={() => setStep(step + 1)}>
                    {t("form.investorExperience.startButton")}
                  </Button>
                </div>
              </>
            )}

            {step === 3 && (
              <>
                <UserInvestorExperience setStep={setStep} currentStep={step} onClose={onClose} />
              </>
            )}

            {step < 2 && (
              <div className="mt-4 w-full flex items-center justify-center px-2">
                <MobileSteps step={step} setStep={setStep} totalSteps={5} />
              </div>
            )}
          </div>
        </div>
      </div>
      <WhyTaxModal isOpen={store.taxModalOpen} onClose={() => store.setTaxModalOpen(false)} />
    </FullScreenModal>
  )
}

export default UserRegulatoryModal
