/* eslint-disable max-len */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { InformationCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "next-i18next";
import Button from "components/ui/Button";

export default function KYCExplanationModal () {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation("common");

  const modalList = [
    {
      title : t("infoModal.kycExplanation.listOneTitle"),
      text  : t("infoModal.kycExplanation.listOneText")
    },
    {
      title : t("infoModal.kycExplanation.listTwoTitle"),
      text  : t("infoModal.kycExplanation.listTwoText")
    },
    {
      title : t("infoModal.kycExplanation.listThreeTitle"),
      text  : t("infoModal.kycExplanation.listThreeText")
    }
  ];

  return (
    <>
      <button onClick={() => setOpen(!open)} >
        <InformationCircleIcon className="h-4 w-4 text-brand-dark" />
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:-mt-20 sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                  <div className="absolute top-0 right-0 pt-2 pr-2">
                    <button
                      type="button"
                      className="rounded-full p-1 hover:bg-light-green text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">{t("infoModal.close")}</span>
                      <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-light-green sm:mx-0 sm:h-10 sm:w-10">
                        <InformationCircleIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
                      </div>

                      <div className="mt-3 text-left sm:mt-0 sm:ml-4 tracking-wide">
                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                          {t("infoModal.kycExplanation.title")}
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-800">
                            {t("infoModal.kycExplanation.subTitle")}
                          </p>
                        </div>

                        <div className="mt-2">
                          <ul>
                            {modalList.map((item, index) => (
                              <li key={index} className="text-sm text-gray-600 mb-2">
                                <span className="font-bold">{item.title}</span> <br></br>
                                <span>{item.text}</span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 flex sm:px-6">
                    <Button
                      type="button"
                      variant="secondary"
                      className="mx-auto "
                      onClick={() => setOpen(false)}
                    >
                      {t("infoModal.close")}
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}