import { ArrowRightIcon, MinusCircleIcon } from "@heroicons/react/24/outline"
import { PlusIcon, UserIcon } from "@heroicons/react/24/solid"
import { useTranslation } from "next-i18next"
import React, { FC, useCallback } from "react"
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import useStore from "store"
import { trpc } from "utils/trpc"

import Button from "components/ui/Button"
import { BirthDate } from "components/ui/form"

type UserChild = {
  birthMonthAndYear: string
}

type FormData = {
  kids: UserChild[]
}

type Props = {
  currentStep: number
  setStep: (value: number) => void
}

const KidsForm: FC<Props> = ({ currentStep, setStep }) => {
  const store = useStore()
  const initialChildren = store.authUser?.profile?.children
  const utils = trpc.useContext()

  const { t } = useTranslation("common")

  const { handleSubmit, control } = useForm<FormData>({
    defaultValues: {
      kids: initialChildren ?? [],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "kids",
  })

  const { isLoading, mutate: updateChildren } = trpc.useMutation("user.updateChildren", {
    onSuccess() {
      utils.invalidateQueries(["user.me"])
      setStep(currentStep + 1)
    },
    onError(error) {
      toast.error("Opps! Something went wrong")
    },
  })

  const onSubmitHandler: SubmitHandler<FormData> = (values: FormData) => {
    updateChildren({ children: values.kids })
  }

  const handleNoChildrenPressed = useCallback(() => {
    updateChildren({ children: [] })
  }, [updateChildren])

  const handleAddChildPressed = useCallback(() => {
    append({ birthMonthAndYear: "" })
  }, [append])

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className="">
      <div className="grid grid-cols-[auto_auto] grid-flow-row gap-4 justify-start grid-rows-auto">
        {fields.map((field, index) => (
          <div className={"relative grid grid-flow-col grid-cols-1 gap-4 pressable-card w-36 px-3 pb-3 pt-5 min-h-[150px]"} key={field.id}>
            <MinusCircleIcon className="absolute top-2 right-2 w-[20px] h-[20px] rounded-full text-brand-dark hover:cursor-pointer" onClick={() => remove(index)} />
            <UserIcon className="self-center w-12 h-12 row-start-1 text-brand-dark place-self-center" />
            <div className="row-start-2">
              <Controller
                control={control}
                name={`kids.${index}.birthMonthAndYear`}
                render={({ field, fieldState }) => <BirthDate mask="MM / YYYY" value={field.value} onChange={field.onChange} name={field.name} errormessage={fieldState.error?.message} />}
                rules={{
                  validate: (newValue) => {
                    return newValue && newValue !== "" ? true : t("task.userDetails.kidsBirthDateRequired")
                  },
                }}
              />
            </div>
          </div>
        ))}
        <button onClick={handleAddChildPressed} className={`pressable-card w-36 min-h-[150px]`} type="button">
          <div className="grid grid-rows-[56px_auto] items-center place-items-center gap-4">
            <div className="flex flex-row items-center justify-center row-start-1 rounded-full w-14 h-14 bg-light-grey aspect-square">
              <PlusIcon className="w-10 h-10 text-brand-dark" />
            </div>

            <div className="row-start-2">{t("task.userDetails.kidsAddChild")}</div>
          </div>
        </button>
      </div>

      <div className="grid-flow-row mt-8 gid">
        <Button variant="primary" type="submit" className={"mt-2 mx-auto w-full"} id="submit" loading={isLoading}>
          {t("form.continueButton")}
        </Button>
        <Button variant="secondary" type="button" className={"mt-2 mx-auto w-full"} onClick={handleNoChildrenPressed} disabled={fields.length > 0}>
          {t("task.userDetails.kidsNoKids")}
        </Button>
      </div>
    </form>
  )
}

export default KidsForm
