import { IdentificationIcon, SignalIcon, UserCircleIcon } from "@heroicons/react/24/outline"
import { DocumentStatus, DocumentType } from "@prisma/client"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import useStore from "store"
import { trpc } from "utils/trpc"

import DataLockedModal from "./DataLockedModal"
import InSignModal from "./InSignModal"
import PreSignModal from "./PreSignModal"
import UserRegulatoryModal from "./RegulatoryDetails"
import SigningCompleteModal from "./SigningCompleteModal"
import TaskCard from "./TaskCard"
import UserProfileModal from "./UserDetails"

const iconClassName = "h-6 w-6 sm:h-8 sm:w-8"

const TaskList = () => {
  const { query } = useRouter()
  const [userDetailsModalOpen, setUserDetailsModalOpen] = useState(false)
  const [userRegulatoryModalOpen, setUserRegulatoryModalOpen] = useState(false)
  const [dataLockedModalOpen, setDataLockedModalOpen] = useState(false)
  const [preSignModalOpen, setPreSignModalOpen] = useState(false)
  const [inSignModalOpen, setInSignModalOpen] = useState(false)
  const [signingCompleteModalOpen, setSigningCompleteModalOpen] = useState(false)
  const [signingComplete, setSigningComplete] = useState(false)
  const store = useStore()
  const user = store.authUser
  const setGlobalLoading = store.setGlobalLoading
  const setOnboardingStep = store.setOnboardingStep
  // const params = new URLSearchParams(window.location.search);
  // const docusignEvent = params.get("event")
  const { t } = useTranslation("common")

  const completedProfileTasks = user?.profile?.placeOfBirth && user.address
  const completedRegulatoryTasks =
    user?.regulatoryProfile?.amlDetails &&
    user?.regulatoryProfile?.taxDetails &&
    user?.regulatoryProfile?.investorExperience &&
    user.regulatoryProfile.investmentExperience
  const hasSigned = user?.documents?.some(
    (doc) => doc.type === DocumentType.KYC && doc.status === DocumentStatus.COMPLETED,
  )
  const hasDocumentCreated = user?.documents?.some((doc) => doc.type === DocumentType.KYC)

  const { mutate: signRegulatory, isLoading: signRegulatoryMutationIsLoading } = trpc.useMutation(
    ["sign.signRegulatory"],
    {
      onMutate() {
        setGlobalLoading(true)
      },
      onSuccess(data) {
        // @typescript-eslint/ban-ts-comment
        // @ts-ignore: No index signature
        window.location.href = data.redirectUrl
      },
      onError(error) {
        console.log(error)
        setGlobalLoading(false)
        toast.error("Opps! Something went wrong")
      },
    },
  )

  const startOrContinueSigningClicked = () => {
    if (!hasDocumentCreated && !hasSigned) {
      setPreSignModalOpen(true)
    } else if (hasDocumentCreated) {
      setInSignModalOpen(true)
    }
  }

  // useEffect(() => {
  //   if (docusignEvent === "signing_complete") {
  //     setSigningComplete(true);
  //   }
  // }, [docusignEvent]);
  useEffect(() => {
    if (query?.event === "signing_complete") {
      setSigningComplete(true)
    }
  }, [query])

  useEffect(() => {
    if (signingComplete || hasSigned) {
      setSigningCompleteModalOpen(true)
    }
  }, [signingComplete, hasSigned, setOnboardingStep])

  return (
    <>
      <div className="text-sm mb-4 font-medium text-gray-700">{t("taskCard.title")}</div>
      <div className="flex flex-col gap-y-4 sm:gap-y-6 relative">
        {/* Lets add some nice animation here
            <div className="absolute top-0 -left-4 w-80 h-80 bg-brand-dark rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob" />
            <div className="absolute top-20 -right-4 w-72 h-72 bg-brand-dark rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"/>
            <div className="absolute -bottom-8 left-20 w-72 h-72 bg-brand-dark rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"/>
            */}

        <TaskCard
          title={t("taskCard.personalDetails.title")}
          description={t("taskCard.personalDetails.description")}
          icon={<UserCircleIcon className={iconClassName} />}
          status={completedProfileTasks ? "done" : "todo"}
          onClick={() => {
            if (hasDocumentCreated) {
              setDataLockedModalOpen(true)
            } else {
              setUserDetailsModalOpen(true)
            }
          }}
        />
        <TaskCard
          title={t("taskCard.regulatoryDetails.title")}
          description={t("taskCard.regulatoryDetails.description")}
          icon={<div className={iconClassName + " text-center"}>§</div>}
          status={completedRegulatoryTasks ? "done" : "todo"}
          onClick={() => {
            if (hasDocumentCreated) {
              setDataLockedModalOpen(true)
            } else {
              setUserRegulatoryModalOpen(true)
            }
          }}
          disabled={!completedProfileTasks}
        />
        <TaskCard
          title={t("taskCard.identityVerification.title")}
          description={t("taskCard.identityVerification.description")}
          icon={<IdentificationIcon className={iconClassName} />}
          status={signingComplete || hasSigned ? "done" : "todo"}
          disabledOnDone={signingComplete || hasSigned}
          onClick={startOrContinueSigningClicked}
          disabled={!completedRegulatoryTasks}
        />
      </div>

      <UserProfileModal
        isOpen={userDetailsModalOpen}
        onClose={() => {
          if (hasDocumentCreated) {
            setDataLockedModalOpen(true)
          } else {
            setUserDetailsModalOpen(false)
          }
        }}
      />
      <UserRegulatoryModal
        isOpen={userRegulatoryModalOpen}
        onClose={() => {
          if (hasDocumentCreated) {
            setDataLockedModalOpen(true)
          } else {
            setUserRegulatoryModalOpen(false)
          }
        }}
      />
      <PreSignModal
        isOpen={preSignModalOpen}
        isDisabled={signRegulatoryMutationIsLoading}
        onClose={() => setPreSignModalOpen(false)}
        onContinue={() => signRegulatory()}
      />
      <InSignModal
        isOpen={inSignModalOpen}
        isDisabled={signRegulatoryMutationIsLoading}
        onClose={() => setInSignModalOpen(false)}
        onContinue={() => signRegulatory()}
      />
      <SigningCompleteModal isOpen={signingCompleteModalOpen} />
      <DataLockedModal
        isOpen={dataLockedModalOpen}
        onClose={() => setDataLockedModalOpen(false)}
        onContinue={() => setDataLockedModalOpen(false)}
      />
    </>
  )
}

export default TaskList
