/* eslint-disable max-len */
import { Dialog, Transition } from "@headlessui/react"
import { LockClosedIcon, XMarkIcon } from "@heroicons/react/24/outline"
import { useTranslation } from "next-i18next"
import { Fragment } from "react"

import Button from "components/ui/Button"
import PassportScan from "components/ui/PassportScan"

interface Props {
  isOpen: boolean
  isDisabled: boolean
  onClose: () => void
  onContinue: () => void
}

export default function InSignModal({ isOpen, isDisabled, onClose, onContinue }: Props) {
  const { t } = useTranslation("common")

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center sm:-mt-20 sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl">
                  <div className="absolute top-0 right-0 pt-2 pr-2">
                    <button
                      type="button"
                      className="rounded-full p-1 hover:bg-light-green text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={onClose}
                    >
                      <span className="sr-only">{t("infoModal.close")}</span>
                      <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-16 w-16 flex-shrink-0 items-center justify-center">
                        <PassportScan />
                      </div>

                      <div className="mt-3 text-left sm:mt-0 sm:ml-4 tracking-wide">
                        <Dialog.Title
                          as="h3"
                          className="text-lg font-medium leading-6 text-gray-900"
                        >
                          {t("infoModal.inSign.title")}
                        </Dialog.Title>
                        <div className="mt-2 gap-y-2 flex flex-col text-sm sm:text-base text-gray-800">
                          <p>{t("infoModal.inSign.subTitle")}</p>

                          <p className="italic text-sm text-red-600">
                            {t("infoModal.inSign.infoStressed")}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 flex sm:px-6">
                    <div className="mx-auto sm:mx-0 sm:ml-auto flex">
                      <Button
                        type="button"
                        variant="primary"
                        disabled={isDisabled}
                        onClick={onContinue}
                      >
                        {t("form.continueButton")}
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
