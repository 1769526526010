import React, { useEffect } from "react";
import lottie from "lottie-web";
import passport from "./passportScan.json";
import cn from "classnames";

interface Props {
  style?: React.CSSProperties;
  className?: string;
  id?: string;
  dark?: boolean;
}

export default function PassportScan ({ style, className, id }: Props): JSX.Element {
  const [animation, setAnimation] = React.useState<any>(null);

  useEffect(() => {
    if (animation) {
      return;
    }
    const iconMenu = document.querySelector(id ? `#${id}` : "#passportScan");

    if (!iconMenu) return;

    const instance = lottie.loadAnimation({
      container     : iconMenu,
      animationData : passport,
      renderer      : "svg",
      autoplay      : false,
      loop          : true
    });

    setAnimation(iconMenu);

    instance.play();

    return () => instance.destroy();
  }, []);

  return (
    <>
      <div
        style={{
          ...style
        }}
        id={id || "passportScan"} className={cn(className, "w-32 h-32 passportScan")} />
    </>
  );
}
