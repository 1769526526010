import { FullScreenModal } from "components/ui/Modal";
import DocumentSignedAnimation from "components/ui/SigningCompleteAnimation";
import { useTranslation } from "next-i18next";

interface Props {
    isOpen: boolean;
}

const SigningCompleteModal = ({ isOpen }: Props) => {
  const { t } = useTranslation("common");

  return (
    <FullScreenModal isOpen={isOpen}>
      <div className="min-h-screen">

        <div className="flex justify-center items-center h-screen">

          <div className="-mt-32 flex flex-col justify-center relative px-8 text-center max-w-xl">
            <div className="mx-auto">
              <DocumentSignedAnimation />
            </div>

            <div className="-mt-8 sm:-mt-4 flex flex-col px-8">
              <div className="text-lg sm:text-xl tracking-wide font-medium">
                {t("infoModal.signSucessfull.title")}
              </div>
              <p className="text-base mt-2">
                {t("infoModal.signSucessfull.sentenceOne")}
              </p>
            </div>
          </div>

        </div>

      </div>
    </FullScreenModal>
  );
};

export default SigningCompleteModal;