import cn from "classnames"
import lottie from "lottie-web"
import React, { useEffect } from "react"

import sucess from "./sucessCheck.json"

interface Props {
  style?: React.CSSProperties
  className?: string
  id?: string
  dark?: boolean
}

export default function SucessCheck({ style, className, id }: Props): JSX.Element {
  const [animation, setAnimation] = React.useState<any>(null)

  useEffect(() => {
    if (animation) {
      return
    }
    const iconMenu = document.querySelector(id ? `#${id}` : "#sucessCheck")

    if (!iconMenu) return

    const instance = lottie.loadAnimation({
      container: iconMenu,
      animationData: sucess,
      renderer: "svg",
      autoplay: false,
      loop: false,
    })

    setAnimation(iconMenu)

    instance.play()

    return () => instance.destroy()
  }, [])

  return (
    <>
      <div
        style={{
          ...style,
        }}
        id={id || "sucessCheck"}
        className={cn(className, "w-32 h-32 sucessCheck")}
      />
    </>
  )
}
