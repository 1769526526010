/* eslint-disable no-undef */
/// /node_modules/@types/googlemaps/index.d.ts" />
import { ArrowRightIcon } from "@heroicons/react/24/outline"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "next-i18next"
import { FC, useMemo } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { userInvestmentExperienceInput } from "server/router/inputs"
import useStore from "store"
import { trpc } from "utils/trpc"

import { IInvestmentExperience } from "types/common"

import Button from "components/ui/Button"
import { Toggle } from "components/ui/form"

type FormProps = {
  currentStep: number
  setStep: (step: number) => void
}

const UserInvestExperienceForm: FC<FormProps> = ({ currentStep, setStep }) => {
  const store = useStore()
  const user = store.authUser
  const utils = trpc.useContext()
  const { t } = useTranslation("common")

  const { control, handleSubmit, getValues, setValue } = useForm<IInvestmentExperience>({
    resolver: zodResolver(userInvestmentExperienceInput),
    defaultValues: {
      tokenizedInvestments:
        user?.regulatoryProfile?.investmentExperience?.tokenizedInvestments || false,
      bonds: user?.regulatoryProfile?.investmentExperience?.bonds || false,
      stocks: user?.regulatoryProfile?.investmentExperience?.stocks || false,
      closedInvestmentFunds:
        user?.regulatoryProfile?.investmentExperience?.closedInvestmentFunds || false,
      hedgeFunds: user?.regulatoryProfile?.investmentExperience?.hedgeFunds || false,
      certificates: user?.regulatoryProfile?.investmentExperience?.certificates || false,
      options: user?.regulatoryProfile?.investmentExperience?.options || false,
    },
  })

  const investQuestions = useMemo(() => {
    return [
      { text: t("form.investQuestions.tokenizedInvestments"), name: "tokenizedInvestments" },
      { text: t("form.investQuestions.bonds"), name: "bonds" },
      { text: t("form.investQuestions.stocks"), name: "stocks" },
      { text: t("form.investQuestions.closedInvestmentFunds"), name: "closedInvestmentFunds" },
      { text: t("form.investQuestions.hedgeFunds"), name: "hedgeFunds" },
      { text: t("form.investQuestions.certificates"), name: "certificates" },
      { text: t("form.investQuestions.options"), name: "options" },
    ] as const
  }, [t])

  const { isLoading, mutate: updateInvestmentExperience } = trpc.useMutation(
    [
      user?.regulatoryProfile?.investmentExperience
        ? "regulatory.updateInvestmentExperience"
        : "regulatory.createInvestmentExperience",
    ],
    {
      onSuccess() {
        utils.invalidateQueries(["user.me"])
        setStep(currentStep + 1)
      },
      onError(error) {
        console.log(error)
        toast.error("Opps! Something went wrong")
      },
    },
  )

  const onSubmitHandler: SubmitHandler<IInvestmentExperience> = (values) => {
    updateInvestmentExperience(values)
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className="grid grid-cols-12 gap-4">
      {investQuestions.map((question, index) => {
        const { name, text } = question
        const values = getValues()
        const value = values[name]

        return (
          <div className="col-span-12 pb-4 border-b border-black border-opacity-5" key={index}>
            <Controller
              name={name}
              control={control}
              render={({ field }) => (
                <Toggle
                  label={text}
                  // name={name}
                  // onChange={(v: boolean) => setValue(name, v)}
                  // value={value}
                  {...field}
                />
              )}
            />
          </div>
        )
      })}

      <div className="col-span-12">
        <Button
          variant="primary"
          type="submit"
          className={"mt-2 mx-auto w-full"}
          id="submit"
          loading={isLoading}
        >
          {t("form.continueButton")}
          <ArrowRightIcon className="ml-2 h-3 w-3 text-white" />
        </Button>
      </div>
    </form>
  )
}

export default UserInvestExperienceForm
