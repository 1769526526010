/* eslint-disable max-len */
import { CheckIcon, HomeIcon, HomeModernIcon } from "@heroicons/react/24/solid"
import cn from "classnames"
import { useTranslation } from "next-i18next"

import TaskList from "./Tasks"
import KYCExplanationModal from "./Tasks/KYCExplanationModal"

interface Props {
  currentStep: number
}

export default function OnboardingSteps({ currentStep }: Props) {
  const { t } = useTranslation("common")
  const steps = [
    {
      name: t("onboardingSteps.step1"),
      component: <TaskList />,
      infoModal: <KYCExplanationModal />,
    },
    {
      name: t("onboardingSteps.step2"),
      component: <></>,
    },
    {
      name: t("onboardingSteps.step3"),
      component: <></>,
    },
    {
      name: t("onboardingSteps.step4"),
      component: <></>,
    },
  ]

  return (
    <>
      <ol className="overflow-hidden pb-16">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className={cn(stepIdx !== steps.length - 1 ? "pb-4" : "", "relative")}>
            {stepIdx < currentStep ? (
              <>
                {stepIdx !== steps.length - 1 ? <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-brand-dark" aria-hidden="true" /> : null}
                <div className="group relative flex items-start">
                  <span className="flex h-9 items-center">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-brand-dark group-hover:bg-brand-dark">
                      <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 mt-1.5 flex-col">
                    <span className="text-base font-medium">{step.name}</span>
                  </span>
                </div>
              </>
            ) : stepIdx === currentStep ? (
              <>
                {stepIdx !== steps.length - 1 ? <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-brand-dark" aria-hidden="true" /> : null}
                <div className="group relative flex items-start w-full" aria-current="step">
                  <span className="flex h-9 items-center" aria-hidden="true">
                    <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-brand-dark bg-white">
                      <span className="h-2.5 w-2.5 rounded-full bg-brand-dark" />
                    </span>
                  </span>
                  <span className="ml-2 sm:ml-4 flex min-w-0 w-full sm:max-w-xl flex-col mt-1.5">
                    <span className="text-base font-bold text-brand-dark flex items-center">
                      {step.name}
                      {step.infoModal ? <div className="ml-1 -mb-1.5">{step.infoModal}</div> : null}
                    </span>
                    <div className="pt-2 pr-2">{step.component}</div>
                  </span>
                </div>
              </>
            ) : (
              <>
                {stepIdx !== steps.length - 1 ? <div className="absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-brand-dark bg-opacity-30" aria-hidden="true" /> : null}
                <div className="group relative flex items-start">
                  {stepIdx !== steps.length - 1 && (
                    <span className="flex h-9 items-center" aria-hidden="true">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white ">
                        <span className="h-2.5 w-2.5 rounded-full bg-transparent " />
                      </span>
                    </span>
                  )}
                  {stepIdx === steps.length - 1 && (
                    <span className="flex h-9 items-center" aria-hidden="true">
                      {/* <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white "> */}
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-brand-dark">
                        <HomeIcon className="h-5 w-5 text-brand-light" aria-hidden="true" />
                      </span>
                    </span>
                  )}
                  <span className="ml-4 flex min-w-0 flex-col mt-[7px]">
                    <span className="text-base font-medium text-gray-500">{step.name}</span>
                  </span>
                </div>
              </>
            )}
          </li>
        ))}
      </ol>
    </>
  )
}
