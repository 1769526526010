import { useTranslation } from "next-i18next"
import { useState } from "react"

import KidsForm from "components/Tasks/UserDetails/KidsForm"
import { FullScreenModal } from "components/ui/Modal"

import MobileSteps from "../MobileSteps"
import Steps from "../Steps"
import TaskSuccess from "../TaskSuccess"
import TaskTitle from "../TaskTitle"
import PartnerSpouseQuestion from "./PartnerSpouseQuestion"
import UserAddressForm from "./UserAddressForm"
import UserProfileForm from "./UserProfileForm"

interface Props {
  isOpen: boolean
  onClose: () => void
}

const UserProfileModal = ({ isOpen, onClose }: Props) => {
  const [step, setStep] = useState<number>(0)
  const { t } = useTranslation("common")

  return (
    <FullScreenModal isOpen={isOpen} onClose={onClose}>
      <div className="grid min-h-screen grid-cols-12">
        <div className="items-center justify-center hidden min-h-screen col-span-3 text-white bg-brand-dark sm:flex">
          <Steps
            steps={[
              { name: t("task.userDetails.step1"), step: 0 },
              { name: t("task.userDetails.step2"), step: 1 },
              { name: t("task.userDetails.step3"), step: 2 },
              { name: t("task.userDetails.step4"), step: 3 },
            ]}
            currentStep={step}
            setStep={setStep}
          />
        </div>

        <div className="flex items-center justify-center h-full col-span-12 py-8 sm:col-span-8 md:col-span-9 sm:pt-0">
          <div className="flex flex-col w-full max-w-md p-4">
            {step === 0 && (
              <>
                <TaskTitle title={t("task.userDetails.titlePersonal")} text={t("task.userDetails.textPersonal")} />

                <UserProfileForm setStep={setStep} currentStep={step} />
              </>
            )}
            {step === 1 && (
              <>
                <TaskTitle title={t("task.userDetails.titleAddress")} />

                <UserAddressForm setStep={setStep} currentStep={step} />
              </>
            )}

            {step === 2 && <PartnerSpouseQuestion step={step} setStep={setStep} />}

            {step === 3 && (
              <>
                <TaskTitle title={t("task.userDetails.titleKids")} text={t("task.userDetails.textKids")} />

                <KidsForm currentStep={step} setStep={setStep} />
              </>
            )}

            {step === 4 && <TaskSuccess title={t("task.userDetails.success")} buttonNextText={t("form.continueButton")} onClick={onClose} />}

            <div className="flex items-center justify-center w-full px-2 mt-4">
              <MobileSteps step={step} setStep={setStep} totalSteps={3} />
            </div>
          </div>
        </div>
      </div>
    </FullScreenModal>
  )
}

export default UserProfileModal
