import { Dialog, Transition } from "@headlessui/react";
import { FC, useRef, ReactNode, Fragment } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  children: ReactNode;
};

const ModalContainer: FC<Props> = ({
  children,
  isOpen,
  onClose,
  className
}) => {
  const modelRef = useRef<HTMLDivElement | null>(null);
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        open={isOpen}
        onClose={onClose}
        className="relative z-50"
        initialFocus={modelRef}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-auto">
          <Dialog.Panel ref={modelRef} className={className}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {children}
            </Transition.Child>
          </Dialog.Panel>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalContainer;
