/* eslint-disable no-undef */
import { RadioGroup } from "@headlessui/react"
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline"
import { zodResolver } from "@hookform/resolvers/zod"
import { TrueFalse } from "@prisma/client"
import cn from "classnames"
import { useTranslation } from "next-i18next"
import { FC, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { userInvestorExperienceInput } from "server/router/inputs"
import useStore from "store"
import { trpc } from "utils/trpc"

import { IInvestorExperience } from "types/common"

import Button from "components/ui/Button"

import TaskSuccess from "../TaskSuccess"
import TaskTitle from "../TaskTitle"
import UserInvestExperienceForm from "./UserInvestExperienceForm"

type FormProps = {
  currentStep: number
  setStep: (step: number) => void
  onClose: () => void
}

interface Question {
  text: string
  value: string
}

interface InvestorQuestion {
  text: string
  answers: string[]
}

interface ButtonProps {
  question: Question[]
  setAnswer: (value: string) => void
  answer: string
}

const QuestionButtons = ({ question, setAnswer, answer }: ButtonProps) => {
  const handleChange = (value: string) => {
    setAnswer(value)
  }

  return (
    <RadioGroup value={answer} onChange={handleChange} className="mt-2">
      <RadioGroup.Label className="sr-only"> Choose your answer </RadioGroup.Label>
      <div className="grid grid-cols-12 gap-4">
        {question.map((q) => (
          <RadioGroup.Option
            key={q.text}
            onClick={() => {
              if (q.value === answer) {
                handleChange(q.value)
              }
            }}
            value={q.value}
            className={({ active, checked }) =>
              cn(
                active ? "ring-2 ring-offset-2 ring-brand-dark" : "",
                checked ? "bg-brand-dark border-transparent text-white" : "bg-white border-dark-grey border-opacity-30 text-brand-dark hover:bg-light-grey",
                "border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1 col-span-12 shadow-md cursor-pointer relative",
              )
            }
          >
            <RadioGroup.Label as="span">{q.text}</RadioGroup.Label>
            <ArrowRightIcon className="h-3 w-3 text-brand-dark absolute right-3" />
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

const UserInvestorExperience: FC<FormProps> = ({ onClose }) => {
  const store = useStore()
  const user = store.authUser
  const utils = trpc.useContext()
  const { t } = useTranslation("common")
  const [internalStep, setInternalStep] = useState(0)

  const { getValues, setValue } = useForm<IInvestorExperience>({
    resolver: zodResolver(userInvestorExperienceInput),
    defaultValues: {
      AE1: user?.regulatoryProfile?.investorExperience?.AE1 || "",
      AE2: user?.regulatoryProfile?.investorExperience?.AE2 || "",
      AE3: user?.regulatoryProfile?.investorExperience?.AE3 || "",
      AE4: user?.regulatoryProfile?.investorExperience?.AE4 || "",
      AE5: user?.regulatoryProfile?.investorExperience?.AE5 || "",
      AE6: user?.regulatoryProfile?.investorExperience?.AE6 || "",
      AE7: user?.regulatoryProfile?.investorExperience?.AE7 || "",
      AE8: user?.regulatoryProfile?.investorExperience?.AE8 || "",
      ForceMissedKnowledge: user?.regulatoryProfile?.investorExperience?.ForceMissedKnowledge || TrueFalse.FALSE,
    },
  })

  const { mutate: updateInvestorExperience } = trpc.useMutation([user?.regulatoryProfile?.investorExperience ? "regulatory.updateInvestorExperience" : "regulatory.createInvestorExperience"], {
    onSuccess() {
      utils.invalidateQueries(["user.me"])
    },
    onError(error) {
      console.log(error)
      toast.error("Opps! Something went wrong")
    },
  })

  const { mutate: updateInvestmentExperience } = trpc.useMutation([user?.regulatoryProfile?.investmentExperience ? "regulatory.updateInvestmentExperience" : "regulatory.createInvestmentExperience"])

  //We use this function to evaluate the next step in the concedus questions
  const handleFormLogic = (value: string, fieldName: string): boolean => {
    if (fieldName === "AE1" && (value === "1" || value === "2")) {
      //If they answer no here we must update all the other questions to false.
      const allFalseInvestment = {
        options: false,
        tokenizedInvestments: false,
        bonds: false,
        stocks: false,
        closedInvestmentFunds: false,
        hedgeFunds: false,
        certificates: false,
      }
      //If they answer no here we must update all the other questions to false.
      updateInvestmentExperience(allFalseInvestment)

      const currentValues = getValues()
      const submitValues = { AE1: value, ForceMissedKnowledge: TrueFalse.TRUE }
      updateInvestorExperience({ ...currentValues, ...submitValues })

      setInternalStep(9)
      return true
    }
    if (fieldName === "AE5" && value === "2") {
      setValue(fieldName, value)
      setValue("AE6", "")
      setInternalStep(7)
      return true
    }
    if (fieldName === "AE7" && value === "2") {
      const currentValues = getValues()
      const submitValues = { AE7: value }
      updateInvestorExperience({ ...currentValues, ...submitValues })
      setInternalStep(9)
      return true
    }
    return false
  }

  const handleAnswer = (value: string, fieldName: any) => {
    const isEnd = handleFormLogic(value, fieldName)

    if (!isEnd) {
      setValue(fieldName, value)
      setInternalStep(internalStep + 1)
    }

    if (internalStep === 8) {
      const currentValues = getValues()
      const submitValues = { ForceMissedKnowledge: TrueFalse.FALSE }
      updateInvestorExperience({ ...currentValues, ...submitValues })
    }
  }

  const translatedeQuestions: [] = useMemo(() => {
    return t("form.investorExperience.questions", { returnObjects: true })
  }, [t])

  const translationsToQuestions = useMemo(() => {
    return translatedeQuestions.map((q: InvestorQuestion, i) => {
      return {
        name: "AE" + (i + 1),
        text: q.text,
        answers: q.answers.map((a: string, i: number) => {
          return {
            text: a,
            value: `${i + 1}`,
          }
        }),
      }
    })
  }, [translatedeQuestions])

  return (
    <>
      {internalStep === 1 && (
        <div className="col-span-12">
          <TaskTitle title={t("task.regulatoryDetails.titleInvestment")} text={t("task.regulatoryDetails.textInvestment")} />

          <UserInvestExperienceForm currentStep={internalStep} setStep={setInternalStep} />
        </div>
      )}

      <form className="grid grid-cols-12 gap-4 p-8">
        {translationsToQuestions.map((question, index) => {
          const { text, name, answers } = question
          const values = getValues()

          const value = values[name as keyof IInvestorExperience]
          if (index === 0 && internalStep === 0) {
            return (
              <div key={index + name} className="col-span-12">
                <div className="text-xl font-medium tracking-wide">{text}</div>
                <div className="mt-4">
                  <QuestionButtons question={answers} setAnswer={(value) => handleAnswer(value, question.name)} answer={value} />
                </div>
              </div>
            )
          }
          if (index === 1 && internalStep === 2) {
            return (
              <div key={index + name} className="col-span-12">
                <div className="text-xl font-medium tracking-wide">{text}</div>
                <div className="mt-4">
                  <QuestionButtons question={answers} setAnswer={(value) => handleAnswer(value, question.name)} answer={value} />
                </div>
              </div>
            )
          }
          if (index > 1 && internalStep === index + 1) {
            return (
              <div key={index + name} className="col-span-12">
                <div className="text-xl font-medium tracking-wide">{text}</div>
                <div className="mt-4">
                  <QuestionButtons question={answers} setAnswer={(value) => handleAnswer(value, question.name)} answer={value} />
                </div>
              </div>
            )
          }
        })}

        {internalStep > 1 && internalStep < 9 && (
          <Button variant="secondary" onClick={() => setInternalStep(internalStep - 1)} className="flex" type="button">
            <ArrowLeftIcon className="h-4 w-4 text-brand-dark opacity-50 absolute left-2" />
          </Button>
        )}

        {internalStep === 9 && (
          <div className="col-span-12 text-center">
            <TaskSuccess title={t("form.investorExperience.success")} buttonNextText={t("form.continueButton")} onClick={onClose} />
          </div>
        )}
      </form>
    </>
  )
}

export default UserInvestorExperience
