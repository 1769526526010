import ModalContainer from "./ModalContainer";
import { ReactNode } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface Props {
    isOpen: boolean;
    onClose?: () => void;
    children: ReactNode;
}

const CloseButton = ({ onClose }: {onClose: () => void}) => (
  <div className="absolute top-2 right-2 sm:top-8 sm:right-8 sm:pt-4 sm:pr-4">
    <button
      type="button"
      className="group hover:bg-light-green p-3 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-dark"
      onClick={onClose}
    >
      <span className="sr-only">Close</span>
      <XMarkIcon className="h-8 w-8 group-hover:text-brand-dark" aria-hidden="true" />
    </button>
  </div>
);

const FullScreenModal = ({ isOpen, onClose, children }: Props) => {

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <ModalContainer isOpen={isOpen} onClose={handleClose} >
      <div className="min-h-screen w-screen bg-light-grey relative rounded overflow-hidden">
        {onClose &&
        <CloseButton onClose={handleClose} />
        }

        {children}

      </div>
    </ModalContainer>
  );
};

export default FullScreenModal;