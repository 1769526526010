/* eslint-disable no-undef */
import { ArrowRightIcon } from "@heroicons/react/24/outline"
import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslation } from "next-i18next"
import { FC } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { userProfileInput } from "server/router/inputs"
import useStore from "store"
import { trpc } from "utils/trpc"

import Button from "components/ui/Button"
import { CountrySelect, GooglePlacesSearch, TextInput } from "components/ui/form"
import BirthDate from "components/ui/form/Birthdate"

export interface FormData {
  firstName: string
  lastName: string
  dateOfBirth: string
  placeOfBirth: string
  nationality: string
  birthCountry: string
}

type UserProfileFormProps = {
  currentStep: number
  setStep: (value: number) => void
}

const UserProfileForm: FC<UserProfileFormProps> = ({ currentStep, setStep }) => {
  const store = useStore()
  const user = store.authUser
  const utils = trpc.useContext()
  const { t } = useTranslation("common")

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(userProfileInput),
    defaultValues: {
      firstName: user?.profile?.firstName || "",
      lastName: user?.profile?.lastName || "",
      dateOfBirth: user?.profile?.dateOfBirth || "",
      placeOfBirth: user?.profile?.placeOfBirth || "",
      nationality: user?.profile?.nationality || "",
      birthCountry: user?.profile?.birthCountry || "",
    },
  })

  const { isLoading, mutate: updateProfile } = trpc.useMutation(
    [user?.profile ? "user.updateProfile" : "user.createProfile"],
    {
      onSuccess() {
        utils.invalidateQueries(["user.me"])
        setStep(currentStep + 1)
      },
      onError(error) {
        console.log(error)
        toast.error("Opps! Something went wrong")
      },
    },
  )

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    updateProfile(values)
  }

  const handlePlaceOfBirthChange = (place: google.maps.GeocoderResult) => {
    // const subLocality = place.address_components.find(
    //   (component: google.maps.GeocoderAddressComponent) => component.types.includes("political"),
    // )
    const city = place?.address_components?.find(
      (component: google.maps.GeocoderAddressComponent) => component.types.includes("locality"),
    )
    const country = place?.address_components?.find(
      (component: google.maps.GeocoderAddressComponent) => component.types.includes("country"),
    )

    // const placeOfBirth = `${subLocality?.long_name || ""} ${city?.short_name || ""}`

    setValue("placeOfBirth", city?.short_name || "")
    setValue("birthCountry", country?.short_name || "")
  }

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className="grid grid-cols-12 gap-5">
      <div className="col-span-12 sm:col-span-6">
        <TextInput
          label={t("form.label.firstName")}
          errorMessage={errors.firstName?.message}
          {...register("firstName", { required: true })}
        />
      </div>

      <div className="col-span-12 sm:col-span-6">
        <TextInput
          label={t("form.label.lastName")}
          errorMessage={errors.lastName?.message}
          {...register("lastName", { required: true })}
        />
      </div>

      <div className="col-span-12 sm:col-span-6">
        <BirthDate
          onChange={(v: string) => setValue("dateOfBirth", v)}
          label={t("form.label.dateOfBirth")}
          name="dateOfBirth"
          errormessage={errors.dateOfBirth?.message}
          value={getValues().dateOfBirth}
        />
      </div>

      <div className="col-span-12">
        <GooglePlacesSearch
          label={t("form.label.placeOfBirth")}
          placeholder={t("form.placeholder.placeOfBirth")}
          isMulti={false}
          onChange={(val) => handlePlaceOfBirthChange(val)}
          name="placeOfBirth"
          type="address"
          value={getValues().placeOfBirth}
        />
      </div>

      <div className="col-span-12">
        <CountrySelect
          label={t("form.label.nationality")}
          name="nationality"
          errorMessage={errors.nationality?.message}
          onChange={(v: string) => setValue("nationality", v)}
          value={getValues().nationality}
        />
      </div>

      <div className="col-span-12">
        <Button
          variant="primary"
          type="submit"
          className={"mt-2 mx-auto w-full"}
          id="submit"
          loading={isLoading}
        >
          {t("form.continueButton")}
          <ArrowRightIcon className="ml-2 h-3 w-3 text-white" />
        </Button>
      </div>
    </form>
  )
}

export default UserProfileForm
