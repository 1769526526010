/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from "@heroicons/react/20/solid";

type Step = {
    name: string;
    step: number;
}

interface StepSProps {
    steps: Step[];
    currentStep: number;
    setStep: (val: number) => void;
}

const Steps = ({ steps, currentStep, setStep }: StepSProps) => {
  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8">
      <nav className="flex justify-center" aria-label="Progress">
        <ol role="list" className="space-y-6">
          {steps.map((step) => (
            <li
              key={step.name}
              onClick={() => {
                if (currentStep > step.step) {
                  setStep(step.step);
                }
              }}
              className="cursor-pointer"
            >
              {step.step < currentStep ? (
                <div className="group">
                  <span className="flex items-start">
                    <span className="mt-0.5 relative flex h-6 w-6 flex-shrink-0 items-center justify-center">
                      <CheckCircleIcon
                        className="h-full w-full text-white group-hover:text-light-grey"
                        aria-hidden="true"
                      />
                    </span>
                    <span className="ml-3 text-lg font-medium text-white group-hover:text-light-grey">
                      {step.name}
                    </span>
                  </span>
                </div>
              ) : step.step === currentStep ? (
                <div className="flex items-start" aria-current="step">
                  <span className="mt-0.5 relative flex h-6 w-6 flex-shrink-0 items-center justify-center" aria-hidden="true">
                    <span className="absolute h-5 w-5 rounded-full bg-white opacity-40" />
                    <span className="relative block h-2 w-2 rounded-full bg-white" />
                  </span>
                  <span className="ml-3 text-lg font-medium text-white">{step.name}</span>
                </div>
              ) : (
                < div className="group">
                  <div className="flex items-start">
                    <div className="mt-0.5 relative flex h-6 w-6 flex-shrink-0 items-center justify-center" aria-hidden="true">
                      <div className="h-2 w-2 rounded-full bg-light-grey opacity-50 group-hover:bg-white" />
                    </div>
                    <p className="ml-3 text-lg font-medium text-gray-300 group-hover:text-gray-100">{step.name}</p>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};

export default Steps;