/* eslint-disable no-undef */
/// /node_modules/@types/googlemaps/index.d.ts" />
import { FC } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { GooglePlacesSearch, TextInput } from "components/ui/form";
import Button from "components/ui/Button";
import { trpc } from "utils/trpc";
import useStore from "store";
import { toast } from "react-hot-toast";
import { userAddressInput } from "server/router/inputs";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "next-i18next";

export interface FormData {
  address1: string;
  address2: string;
  address3: string;
  city: string;
  postalCode: string;
  country: string;
}

type UserProfileFormProps = {
  currentStep: number;
  setStep: (value: number) => void;
}

const UserAddressForm: FC<UserProfileFormProps> = ({ currentStep, setStep  }) => {
  const store = useStore();
  const user = store.authUser;
  const utils = trpc.useContext();
  const { t } = useTranslation("common");

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<FormData>({
    resolver      : zodResolver(userAddressInput),
    defaultValues : {
      address1   : user?.address?.address1    || "",
      address2   : user?.address?.address2    || "",
      address3   : user?.address?.address3    || "",
      city       : user?.address?.city || "",
      postalCode : user?.address?.postalCode || "",
      country    : user?.address?.country || ""
    }
  });

  const { isLoading, mutate: updateAddress } = trpc.useMutation([user?.address ? "user.updateAddress" : "user.createAddress"], {
    onSuccess () {
      utils.invalidateQueries(["user.me"]);
      setStep(currentStep + 1);
    },
    onError (error) {
      console.log(error);
      toast.error("Opps! Something went wrong");
    }
  });

  const onSubmitHandler: SubmitHandler<FormData> = (values) => {
    updateAddress(values);
  };

  const populateAddress = (address: google.maps.GeocoderResult) => {
    let address1 = "";
    let city = "";

    address1 = address.formatted_address.split(",")[0] || "";

    for (const component of address.address_components as google.maps.GeocoderAddressComponent[]) {
      const componentType = component.types[0];

      switch (componentType) {
      case "postal_code": {
        setValue("postalCode", component.long_name, { shouldValidate: true });
        break;
      }

      case "locality": {
        city = component.long_name;
        break;
      }

      case "postal_town": {
        city += component.long_name;
        break;
      }

      case "country": {
        setValue("country", component.long_name, { shouldValidate: true });
      }
      }

    }

    setValue("address1", address1);
    setValue("city", city);

  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitHandler)}
      className="grid grid-cols-12 gap-4"
    >
      <div className="col-span-12 mb-12">
        <GooglePlacesSearch
          label={t("form.label.searchAddress")}
          placeholder={t("form.placeholder.startTypeAddress")}
          isMulti={false}
          onChange={(val) => populateAddress(val)}
          name="search"
          type="address"
        />
      </div>

      <div className="col-span-12">
        <TextInput
          label={t("form.label.address")}
          errorMessage={errors.address1?.message}
          {...register("address1", {
          })}
        />
      </div>

      <div className="col-span-12">
        <TextInput
          label={t("form.label.address2")}
          errorMessage={errors.address2?.message}
          {...register("address2", {
          })}
        />
      </div>

      <div className="col-span-12 sm:col-span-6">
        <TextInput
          label={t("form.label.postcode")}
          errorMessage={errors.city?.message}
          {...register("postalCode", {
            required: { value: true, message: "First name is required" }
          })}
        />
      </div>

      <div className="col-span-12">
        <TextInput
          label={t("form.label.city")}
          errorMessage={errors.city?.message}
          {...register("city", {
            required: { value: true, message: "" }
          })}
        />
      </div>
      <div className="col-span-12">
        <TextInput
          label={t("form.label.country")}
          errorMessage={errors.country?.message}
          {...register("country", {
            required: { value: true, message: "" }
          })}
        />
      </div>

      <div className="col-span-12">

        <Button
          variant="primary"
          type="submit"
          className={"mt-2 mx-auto w-full"}
          id="submit"
          loading={isLoading}
        >
          {t("form.continueButton")}
          <ArrowRightIcon className="ml-2 h-3 w-3 text-white" />
        </Button>
      </div>
    </form>
  );
};

export default UserAddressForm;