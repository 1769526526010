import { NextPage } from "next"
import { useTranslation } from "next-i18next"
import Head from "next/head"
import React, { useEffect } from "react"
import useStore from "store"

import Navbar from "../components/Navbar"
import LoadingSpinner from "../components/ui/LoadingSpinner.tsx"

interface Props {
  children: React.ReactNode
}

const Layout: NextPage<Props> = ({ children }) => {
  const store = useStore()
  const globalLoading = store.globalLoading
  const { i18n } = useTranslation("common")

  useEffect(() => {
    if (!i18n || !i18n.isInitialized) {
      window.location.reload()
    }
  }, [i18n])

  return (
    <>
      <Head>
        <title>MYNE Digital Onboarding</title>
        <meta name="description" content="MYNE Digital Onboarding" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="robots" content="noindex,nofollow" />
      </Head>

      <main className="flex flex-col min-h-screen relative bg-brand-light ">
        <Navbar />

        {globalLoading && (
          <div className="absolute flex items-center justify-center h-screen w-screen bg-brand-dark bg-opacity-80 z-[999]">
            <div className="flex flex-col gap-y-4">
              <LoadingSpinner className="h-64 w-64 -mt-32" global={true} dark={false} />
            </div>
          </div>
        )}

        {children}
      </main>
    </>
  )
}

export default Layout
