interface Props {
  title: string
  text?: string
}

const TaskTitle = ({ title, text }: Props) => (
  <div className="flex flex-col gap-y-2 mb-8">
    <div className="text-3xl text-brand-dark flex">{title}</div>
    {!!text && <div className="text-base">{text}</div>}
  </div>
)

export default TaskTitle
