import Button from "components/ui/Button";
import SucessCheck from "components/ui/SucessAnimation";

interface Props {
    title: string;
    buttonNextText: string;
    onClick: () => void;
}

const TaskSuccess = ({ title, buttonNextText, onClick }: Props) => (
  <div className="w-full flex justify-center items-center flex-col">

    <SucessCheck />

    <div className="text-2xl">
      {title}
    </div>

    <Button variant="primary" type="button" className="mt-8 mx-auto" onClick={onClick}>
      {buttonNextText}
    </Button>

  </div>
);

export default TaskSuccess;