/* eslint-disable no-undef */
/// /node_modules/@types/googlemaps/index.d.ts" />
import { ArrowRightIcon } from "@heroicons/react/24/outline"
import { zodResolver } from "@hookform/resolvers/zod"
import { TrueFalse } from "@prisma/client"
import { useTranslation } from "next-i18next"
import { FC, useCallback, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { userAMLInput } from "server/router/inputs"
import useStore from "store"
import { trpc } from "utils/trpc"

import { IAML } from "types/common"

import Button from "components/ui/Button"
import { RadioButton, TextInput, Toggle } from "components/ui/form"
import Label from "components/ui/form/Label"

type FormProps = {
  currentStep: number
  setStep: (step: number) => void
}

const UserAMLForm: FC<FormProps> = ({ currentStep, setStep }) => {
  const store = useStore()
  const user = store.authUser
  const utils = trpc.useContext()
  const { t } = useTranslation("common")
  const [internalStep, setInternalStep] = useState(0)

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IAML>({
    resolver: zodResolver(userAMLInput),
    defaultValues: {
      occupation: user?.regulatoryProfile?.amlDetails?.occupation || "",
      sourceFundsCurrentIncome:
        user?.regulatoryProfile?.amlDetails?.sourceFundsCurrentIncome || false,
      sourceFundsInheritance: user?.regulatoryProfile?.amlDetails?.sourceFundsInheritance || false,
      sourceFundsLifeInsurance:
        user?.regulatoryProfile?.amlDetails?.sourceFundsLifeInsurance || false,
      sourceFundsSaleOfAssets:
        user?.regulatoryProfile?.amlDetails?.sourceFundsSaleOfAssets || false,
      sourceFundsSavings: user?.regulatoryProfile?.amlDetails?.sourceFundsSavings || false,
      sourceFundsOther: user?.regulatoryProfile?.amlDetails?.sourceFundsOther || "",

      actOnOwnBehalf: user?.regulatoryProfile?.amlDetails?.actOnOwnBehalf,
      politicallyExposed: user?.regulatoryProfile?.amlDetails?.politicallyExposed,
      lastFiveYearEviction: user?.regulatoryProfile?.amlDetails?.lastFiveYearEviction,
      lastFiveYearForeclosure: user?.regulatoryProfile?.amlDetails?.lastFiveYearForeclosure,
      lastFiveYearAffidavit: user?.regulatoryProfile?.amlDetails?.lastFiveYearAffidavit,
      lastFiveYearBankruptcy: user?.regulatoryProfile?.amlDetails?.lastFiveYearBankruptcy,
      criminalRecord: user?.regulatoryProfile?.amlDetails?.criminalRecord,
    },
  })

  const { isLoading, mutate: updateAMLDetails } = trpc.useMutation(
    [user?.regulatoryProfile?.amlDetails ? "regulatory.updateAMLdata" : "regulatory.createAMLdata"],
    {
      onSuccess() {
        utils.invalidateQueries(["user.me"])
        setStep(currentStep + 1)
      },
      onError(error) {
        console.log(error)
        toast.error("Opps! Something went wrong")
      },
    },
  )

  const onSubmitHandler: SubmitHandler<IAML> = (values) => {
    updateAMLDetails(values)
  }

  useEffect(() => {
    if (getValues().sourceFundsOther) {
      setShowOther(true)
    }
  }, [getValues])

  const [showOther, setShowOther] = useState<boolean>(false)

  const hasSelectedSourceOfFunds = useCallback(() => {
    const values = watch()

    if (showOther && (values.sourceFundsOther === "" || values.sourceFundsOther == undefined)) {
      return false
    }

    return (
      values.sourceFundsCurrentIncome ||
      values.sourceFundsInheritance ||
      values.sourceFundsLifeInsurance ||
      values.sourceFundsSaleOfAssets ||
      values.sourceFundsSavings ||
      (showOther && (values.sourceFundsOther !== "" || values.sourceFundsOther == undefined))
    )
  }, [watch, showOther])

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)} className="grid grid-cols-12 gap-4">
      {internalStep === 0 && (
        <>
          <div className="col-span-12">
            <div className="flex items-center justify-between gap-x-4">
              <Label
                text={t("form.label.actOnOwnBehalf")}
                description={t("form.placeholder.actOnOwnBehalf")}
              />
              <div className="flex gap-x-2">
                <RadioButton
                  {...register("actOnOwnBehalf", { required: true })}
                  value={TrueFalse.TRUE}
                  label={t("form.yes")}
                />
                <RadioButton
                  {...register("actOnOwnBehalf", { required: true })}
                  value={TrueFalse.FALSE}
                  label={t("form.no")}
                />
              </div>
            </div>
          </div>

          <div className="col-span-12">
            <div className="flex items-center justify-between gap-x-4">
              <Label
                text={t("form.label.isPoliticallyExposed")}
                description={t("form.placeholder.isPoliticallyExposed")}
              />
              <div className="flex gap-x-2">
                <RadioButton
                  {...register("politicallyExposed", { required: true })}
                  value={TrueFalse.TRUE}
                  label={t("form.yes")}
                />
                <RadioButton
                  {...register("politicallyExposed", { required: true })}
                  value={TrueFalse.FALSE}
                  label={t("form.no")}
                />
              </div>
            </div>
          </div>

          <div className="col-span-12">
            <div className="flex items-center justify-between gap-x-4">
              <Label text={t("form.label.lastFiveYearEviction")} />
              <div className="flex gap-x-2">
                <RadioButton
                  {...register("lastFiveYearEviction", { required: true })}
                  value={TrueFalse.TRUE}
                  label={t("form.yes")}
                />
                <RadioButton
                  {...register("lastFiveYearEviction", { required: true })}
                  value={TrueFalse.FALSE}
                  label={t("form.no")}
                />
              </div>
            </div>
          </div>

          <div className="col-span-12">
            <div className="flex items-center justify-between gap-x-4">
              <Label text={t("form.label.lastFiveYearForeclosure")} />
              <div className="flex gap-x-2">
                <RadioButton
                  {...register("lastFiveYearForeclosure", { required: true })}
                  value={TrueFalse.TRUE}
                  label={t("form.yes")}
                />
                <RadioButton
                  {...register("lastFiveYearForeclosure", { required: true })}
                  value={TrueFalse.FALSE}
                  label={t("form.no")}
                />
              </div>
            </div>
          </div>

          <div className="col-span-12">
            <div className="flex items-center justify-between gap-x-4">
              <Label text={t("form.label.lastFiveYearAffidavit")} />
              <div className="flex gap-x-2">
                <RadioButton
                  {...register("lastFiveYearAffidavit", { required: true })}
                  value={TrueFalse.TRUE}
                  label={t("form.yes")}
                />
                <RadioButton
                  {...register("lastFiveYearAffidavit", { required: true })}
                  value={TrueFalse.FALSE}
                  label={t("form.no")}
                />
              </div>
            </div>
          </div>

          <div className="col-span-12">
            <div className="flex items-center justify-between gap-x-4">
              <Label text={t("form.label.lastFiveYearBankruptcy")} />
              <div className="flex gap-x-2">
                <RadioButton
                  {...register("lastFiveYearBankruptcy", { required: true })}
                  value={TrueFalse.TRUE}
                  label={t("form.yes")}
                />
                <RadioButton
                  {...register("lastFiveYearBankruptcy", { required: true })}
                  value={TrueFalse.FALSE}
                  label={t("form.no")}
                />
              </div>
            </div>
          </div>

          <div className="col-span-12">
            <div className="flex items-center justify-between gap-x-4">
              <Label text={t("form.label.criminalRecord")} />
              <div className="flex gap-x-2">
                <RadioButton
                  {...register("criminalRecord", { required: true })}
                  value={TrueFalse.TRUE}
                  label={t("form.yes")}
                />
                <RadioButton
                  {...register("criminalRecord", { required: true })}
                  value={TrueFalse.FALSE}
                  label={t("form.no")}
                />
              </div>
            </div>
          </div>
          <div className="col-span-12">
            <Button
              variant="primary"
              className={"mt-2 mx-auto w-full"}
              loading={isLoading}
              onClick={() => setInternalStep(1)}
            >
              {t("form.nextButton")}
              <ArrowRightIcon className="ml-2 h-3 w-3 text-white" />
            </Button>
          </div>
        </>
      )}

      {internalStep === 1 && (
        <>
          <div className="col-span-12">
            <TextInput
              label={t("form.label.occupation")}
              errorMessage={errors.occupation?.message}
              {...register("occupation", { required: true })}
            />
          </div>

          <div className="col-span-12 p-4 flex flex-col border rounded border-gray-300">
            <div className="text-lg font-bold">{t("form.sourceFundsTitle")}</div>
            <div className="text-sm  mb-3">{t("form.sourceFundsText")}</div>
            <div className="flex flex-col gap-y-3">
              <Toggle
                label={t("form.label.sourceFundsCurrentIncome")}
                name="sourceFundsCurrentIncome"
                onChange={(v: boolean) => setValue("sourceFundsCurrentIncome", v)}
                value={getValues().sourceFundsCurrentIncome}
                small
              />
              <Toggle
                label={t("form.label.sourceFundsInheritance")}
                name="sourceFundsInheritance"
                onChange={(v: boolean) => setValue("sourceFundsInheritance", v)}
                value={getValues().sourceFundsInheritance}
                small
              />
              <Toggle
                label={t("form.label.sourceFundsLifeInsurance")}
                name="sourceFundsLifeInsurance"
                onChange={(v: boolean) => setValue("sourceFundsLifeInsurance", v)}
                value={getValues().sourceFundsLifeInsurance}
                small
              />
              <Toggle
                label={t("form.label.sourceFundsSaleOfAssets")}
                name="sourceFundsSaleOfAssets"
                onChange={(v: boolean) => setValue("sourceFundsSaleOfAssets", v)}
                value={getValues().sourceFundsSaleOfAssets}
                small
              />
              <Toggle
                label={t("form.label.sourceFundsSavings")}
                name="sourceFundsSavings"
                onChange={(v: boolean) => setValue("sourceFundsSavings", v)}
                value={getValues().sourceFundsSavings}
                small
              />
              <Toggle
                label={t("form.other")}
                name="other"
                onChange={(v) => {
                  if (v) setShowOther(true)
                  else {
                    setShowOther(!showOther)
                    setValue("sourceFundsOther", "")
                  }
                }}
                value={showOther}
                small
              />
              {showOther && (
                <div className="mb-2">
                  <TextInput
                    label={t("form.label.sourceFundsOther")}
                    errorMessage={errors.sourceFundsOther?.message}
                    {...register("sourceFundsOther", { required: showOther, min: 2 })}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="col-span-12">
            <Button
              variant="primary"
              type="submit"
              className={"mt-2 mx-auto w-full"}
              id="submit"
              loading={isLoading}
              disabled={!hasSelectedSourceOfFunds()}
            >
              {t("form.continueButton")}
              <ArrowRightIcon className="ml-2 h-3 w-3 text-white" />
            </Button>
          </div>
        </>
      )}
    </form>
  )
}

export default UserAMLForm
